/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */

import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import FormEngine, { Form } from '../../Shared/FormEngine';
import { getSchemeJson } from '../../../util/FormJson';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import { GET_ACCOUNTS } from '../../../graphql/getAccounts';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { GET_USER_TBA_ACCOUNTS } from '../../../graphql/getUserTBAAccounts';
import { GET_SHIP_TO_ADDRESSES } from '../../../graphql/getShipToAddresses';
import { GET_PROCEDURE_TYPES } from '../../../graphql/getProcedureTypes';
import { GET_SURGEONS } from '../../../graphql/getSurgeons';
import CREATE_USAGE_SHEET from '../../../graphql/mutations/createUsageSheet';
import CREATE_USAGE_SHEET_AFTER_SFID from '../../../graphql/mutations/createUsageSheetAfterSfid';
import { getUTCDateTime, formatShipMethodsLabel } from '../../../util/utilityFunctions';
import {
  NOTIFY_TYPE,
  USAGE_TYPE,
  SHIP_METHOD_OPTIONS,
  SPORTS_MED,
  OPSCSRUSERS,
} from '../../../util/constants';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import RESUBMIT_ORDER_DETAILS from '../../../graphql/mutations/resubmitOrderDetails';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../graphql/getSubInventoryWithLotDetails';
import { GET_SHIP_METHODS } from '../../../graphql/getShipMethods';

import { GET_USER_TERRITORIES } from '../../../graphql/getUserTerritories';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../graphql/mutations/createUpdateUsageSheetLineItems';

interface VariableType {
  [key: string]: string;
}

interface Comments {
  comment?: string;
  postTime?: string;
  commentType?: string;
  id?: string;
}

interface ShipToBillToAddress {
  address1?: string;
  address2?: string;
  city?: string;
  id?: string;
  isPrimaryAddress?: boolean;
  isPrimaryAccount?: boolean;
  name?: string;
  label?: string;
  value?: string;
  postalcode?: string;
  state?: string;
  number?: string;
  __typename?: string;
}

interface Props extends RouteComponentProps {
  children?: FC;
  isOpen: boolean;
  isAssignUser?: boolean;
  editDetails?: any;
  toggleOpen: () => void;
  refetchDetails?: () => void;
  formData?: any;
  orderType: string;
  eventUsage?: boolean;
  isERPOrder?: boolean;
  handleUsageSheetEdit?: () => void;
  handleCancelButton?: () => void;
  type?: string;
  startLoader?: any;
}

const UsageSheetForm: FC<Props> = ({
  history,
  isOpen,
  isAssignUser,
  toggleOpen,
  refetchDetails,
  // formData,
  orderType,
  editDetails,
  eventUsage,
  isERPOrder,
  handleUsageSheetEdit,
  handleCancelButton,
  startLoader,
  type,
}) => {
  const [createUsageLoading, setLoading] = useState(false);
  const [accountChecked, setAccountChecked] = useState('My Accounts');
  const [selectedSalesRep, setSelectedSalesRep] = useState();
  const { open, notification, openSnackBar } = useSnackBar();
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const [selectedTab, setSelectedTab] = useState('Details');
  const [userTerritories, setUserTerritories] = useState();
  const [accountSelected, setAccountSelected] = useState<any>();
  const userInfo = useSelector(getUserInfo);
  const [getAccounts, { data: accountsData }] = useLazyQuery(GET_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });
  const [getShipMethods, { data: shipMethods }] = useLazyQuery(GET_SHIP_METHODS, {
    fetchPolicy: 'no-cache',
  });
  const [getTBAAccounts, { data: tbaAccountsData }] = useLazyQuery(GET_USER_TBA_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });
  const [getShipToAddresses, { data: shipToData }] = useLazyQuery(GET_SHIP_TO_ADDRESSES, {
    fetchPolicy: 'no-cache',
  });
  const [getBillToAddresses, { data: billToData }] = useLazyQuery(GET_SHIP_TO_ADDRESSES, {
    fetchPolicy: 'no-cache',
  });
  const { data: formData } = useQuery(GET_FORM_CONFIGURATIONS);
  const [createUsageSheet] = useMutation(CREATE_USAGE_SHEET);
  const [createUsageSheetAfterSfid, { loading: createEventAfterSfidLoading }] = useMutation(
    CREATE_USAGE_SHEET_AFTER_SFID
  );
  const [editErpOrderDetails] = useMutation(RESUBMIT_ORDER_DETAILS);
  const { data: procedureTypesData } = useQuery(GET_PROCEDURE_TYPES);
  const [InternalComment, setInternalComments] = useState<any>([]);
  const [CustomerComment, setCustomerComments] = useState<any>([]);
  const [getSurgeons, { data: contactsData }] = useLazyQuery(GET_SURGEONS, {
    fetchPolicy: 'no-cache',
  });
  const [getSubInventoryWithLotDetails, { data: subInventoryWithLotDetails }] = useLazyQuery(
    GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [createUpdateUsageLineItems] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);

  const [usageType, setUsageType] = useState('Usage');
  const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  useEffect(() => {
    if (orderType) {
      setUsageType(orderType);
    }
  }, [orderType]);

  useEffect(() => {
    getAccounts({
      variables: {
        salesRepId: selectedSalesRep ? selectedSalesRep : userInfo?.id,
        limit: 1000,
      },
    });
  }, [getAccounts, userInfo, selectedSalesRep]);

  useEffect(() => {
    getShipMethods({
      variables: {},
    });
  }, []);

  useEffect(() => {
    if (handleUsageSheetEdit) {
      if (refetchDetails) {
        refetchDetails();
      }
    }
  }, [getAccounts, userInfo, selectedSalesRep]);

  useEffect(() => {
    if ((accountSelected && accountSelected?.length > 0) || editDetails) {
      getShipToAddresses({
        variables: {
          userId: userInfo?.id,
          type: 'Shipping',
          region: 'EU',
          searchText: '',
          accountId: accountSelected?.length > 0 ? accountSelected?.[0].id : editDetails?.accountId,
        },
      });
      getBillToAddresses({
        variables: {
          userId: userInfo?.id,
          type: 'Billing',
          region: 'EU',
          searchText: '',
          accountId: accountSelected?.length > 0 ? accountSelected?.[0].id : editDetails?.accountId,
        },
      });
    }
  }, [getShipToAddresses, getBillToAddresses, userInfo, accountSelected, editDetails]);

  useEffect(() => {
    getTBAAccounts({
      variables: {
        salesRepId: selectedSalesRep ? selectedSalesRep : userInfo?.id,
      },
    });
  }, [getTBAAccounts, userInfo, selectedSalesRep]);

  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  useEffect(() => {
    getTerritories({
      variables: {
        userId: selectedSalesRep ? selectedSalesRep : userInfo?.id,
      },
    });
  }, [getTerritories]);

  useEffect(() => {
    getTerritories();
    if (territoryData?.getUserTerritories) {
      const userTerritoriesItemsList = territoryData?.getUserTerritories;
      if (userTerritoriesItemsList && userTerritoriesItemsList.length > 0) {
        setUserTerritories(userTerritoriesItemsList[0].value);
      }
    }
  }, [getTerritories, territoryData]);

  useEffect(() => {
    if (editDetails?.caseExternalId) {
      getSubInventoryWithLotDetails({
        variables: {
          eventExternalId: editDetails?.caseExternalId,
          subInventoryName: userTerritories,
          subInventoryCode: editDetails?.accountNumber ? editDetails?.accountNumber : '',
        },
      });
    }
  }, [getSubInventoryWithLotDetails, userTerritories]);

  useEffect(() => {
    setInternalComments(editDetails?.comments?.filter((item: any) => item.type === 'Internal'));
    setCustomerComments(editDetails?.comments?.filter((item: any) => item.type === 'Customer'));
  }, [editDetails]);

  // changes for excluding accounts having E# as they reprsent repstock in R5
  const getFilteredOptions = (items: any): [] => {
    if (items && items?.length > 0) {
      const accountsWithoutE = items.filter(
        (item: any) =>
          !item?.number?.startsWith('E' || 'e') &&
          !item?.number?.startsWith('IT') &&
          !item?.number?.startsWith('IH')
      );
      if (accountsWithoutE) {
        return (
          accountsWithoutE &&
          accountsWithoutE?.map((item: any) => {
            return {
              ...item,
            };
          })
        );
      }
      return (
        items &&
        items?.map((item: any) => {
          return {
            ...item,
          };
        })
      );
    }
    return [];
  };

  const getOptionsWithLabel = (addressData: any): [] => {
    if (shipToData?.getShipToAddresses) {
      return (
        addressData &&
        addressData.getShipToAddresses &&
        addressData.getShipToAddresses.map((item: any) => {
          return {
            ...item,
            label: `${item?.name ? `${item?.name} -` : ''} ${item.address1 || ''}, ${
              item.address2 ? `${item.address2},` : ''
            } ${item.city || ''}, ${item.state || ''}, ${item.postalcode || ''}`,
          };
        })
      );
    }
    return [];
  };
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const handleSalesRepSelection = useCallback(
    salesRepValue => {
      if (salesRepValue && salesRepValue.length > 0) {
        setSelectedSalesRep(salesRepValue[0].id);
      }
    },
    [setSelectedSalesRep]
  );
  const handleTabChange = (event: any): void => {
    setSelectedTab(event.label);
  };

  const formJson = useCallback(() => {
    try {
      const dataObject = data && data.getFormConfigurations;
      // const payloadObject = getSchemeJson(USAGE_TYPE.BILL_ONLY ? 'Bill Only' : 'shipAndBill');
      const payloadObject: any =
        dataObject[orderType === USAGE_TYPE.BILL_ONLY ? 'billOnly' : 'shipAndBill'];
      const checkField = payloadObject[3]?.fields.filter((i: any) => i.name === 'comments');
      if (checkField.length > 0) {
        payloadObject[3]?.fields?.pop();
      }
      return payloadObject;
    } catch (error) {
      // If the query errors out, need to return something
      return getSchemeJson(orderType);
    }
  }, [data, orderType]);

  const getDefaultShipToSelection = (prevShipTo: ShipToBillToAddress[]): ShipToBillToAddress[] => {
    // const criteria = (record: ShipToBillToAddress) => record?.name === accountSelected?.[0]?.number;
    const criteria = (record: ShipToBillToAddress) => record?.id === accountSelected?.[0]?.id;
    const defaultShipTo = prevShipTo?.find(criteria);
    if (defaultShipTo) {
      if (defaultShipTo.isPrimaryAccount) {
        defaultShipTo.label = `* ${defaultShipTo.label}`;
        defaultShipTo.name = `*  ${defaultShipTo.name}`;
        if (editDetails) {
          defaultShipTo.number = `* ${defaultShipTo.number}`;
        }
      }
      prevShipTo = prevShipTo?.filter(record => !criteria(record));
      prevShipTo.unshift(defaultShipTo);
    }
    return prevShipTo;
  };

  const getDefaultBillToSelection = (prevShipTo: ShipToBillToAddress[]): ShipToBillToAddress[] => {
    const criteria = (record: ShipToBillToAddress) => record.isPrimaryAccount === true;
    const defaultBillTo = prevShipTo?.filter(criteria);
    if (defaultBillTo) {
      // prevShipTo = prevShipTo?.filter(record => !criteria(record));
      prevShipTo = prevShipTo.map(item => {
        if (item.isPrimaryAccount) {
          return { ...item, label: `* ${item.label}`, name: `* ${item.name}` };
        }
        return item;
      });
      // prevShipTo.unshift();
    }
    return prevShipTo;
  };

  const apiDataObj = {
    getSalesReps: salesRepsData?.getSalesReps,
    getAccounts:
      accountChecked === 'My Accounts'
        ? getFilteredOptions(accountsData?.getAccounts)
        : getFilteredOptions(tbaAccountsData?.getUserTBAAccounts),
    getShipToAddresses: getDefaultShipToSelection(getOptionsWithLabel(shipToData)),
    getBillToAddresses: getDefaultBillToSelection(getOptionsWithLabel(billToData)),
    getSurgeons: contactsData?.getSurgeons,
    getProcedureTypes: procedureTypesData?.getProcedureTypes,
    getShipMethods: formatShipMethodsLabel(shipMethods),
  };

  const lazyQueryObject = {
    getShipToAddresses,
    getBillToAddresses,
    getAccounts,
    getTbaAccounts: getTBAAccounts,
    getSurgeons,
  };

  const handleRadioButtonChange = (event: any): void => {
    setAccountChecked(event.target.value);
  };

  useEffect(() => {
    if (editDetails && !eventUsage) {
      const value = !editDetails.isTBAAccount ? 'My Accounts' : 'TBA Accounts';
      setAccountChecked(value);
    }
  }, [editDetails]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('Details');
    }
  }, [isOpen]);

  const formatLineItems = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map(
      (item: {
        actualPackContent: any;
        lotNumber: any;
        productId: any;
        quantity: any;
        productNumber: any;
        isLotNumber: any;
      }): any => {
        finalLineItems.push({
          productId: item?.productId,
          productNumber: item?.productNumber,
          quantity: item?.quantity,
          lotNumber: item?.lotNumber,
          actualPackContent: item?.actualPackContent,
          isLotNumber: item?.isLotNumber,
        });
      }
    );
    return finalLineItems;
  };

  const saveOrderRequest = (result: any): void => {
    const {
      account,
      salesRep,
      shipTo,
      billTo,
      shipMethod,
      procedure,
      contact,
      po,
      surgeryDate,
      patientID,
      internalComments,
      customerComments,
      iId,
      cId,
      lineItems,
    } = result;
    let variables: any = {};
    variables = {
      formType: !eventUsage && editDetails?.caseExternalId ? 'Update' : 'New',
      requestedFrom: orderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only',
      salesRepId: salesRep && salesRep[0].id,
      accountId: account && account[0]?.id,
      branchId: account && account[0]?.branchId,
      shipToId: shipTo && shipTo[0]?.id,
      billToId: billTo && billTo[0]?.id,
      system: 'Web',
      version: process.env.REACT_APP_VERSION || '0.0.1',
    };
    if (editDetails) {
      const { caseId, caseExternalId } = editDetails;
      if (caseId) {
        variables = {
          ...variables,
          caseId,
          eventSFID: caseId,
        };
      }
      if (caseExternalId && eventUsage) {
        variables = {
          ...variables,
          eventExternalId: caseExternalId,
        };
      }
      if (caseExternalId) {
        variables = {
          ...variables,
          caseExternalId,
        };
      }
    }
    if (shipMethod) {
      variables = {
        ...variables,
        shipMethod: shipMethod[0]?.value?.split('-')?.[0],
      };
    }
    if (procedure) {
      variables = {
        ...variables,
        procedureTypeIds: procedure[0]?.id,
      };
    }
    if (contact) {
      variables = {
        ...variables,
        surgeonId: contact[0]?.id || '',
      };
    }
    if (surgeryDate) {
      variables = {
        ...variables,
        eventDateTime: getUTCDateTime(surgeryDate),
      };
    }
    variables = {
      ...variables,
      poNumber: po || '',
    };
    variables = {
      ...variables,
      patientId: patientID || '',
    };
    let comments: Comments[] = [];
    if (internalComments) {
      comments = [
        ...comments,
        {
          comment: internalComments,
          postTime: getUTCDateTime(new Date()),
          commentType: 'Internal',
          id: iId,
        },
      ];
    }
    if (customerComments) {
      comments = [
        ...comments,
        {
          comment: customerComments,
          postTime: getUTCDateTime(new Date()),
          commentType: 'Customer',
          id: cId,
        },
      ];
    }
    if (comments && comments.length > 0) {
      variables = {
        ...variables,
        comments,
      };
    }
    setLoading(true);
    if (startLoader) {
      startLoader(true);
    }
    if (eventUsage) {
      // To create order
      createUsageSheet({
        variables,
      })
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.createUsageSheet &&
            res.data.createUsageSheet.message === NOTIFY_TYPE.SUCCESS
          ) {
            // const { externalId } = res.data.createUsageSheet;
            const { sfid } = res.data.createUsageSheet;
            let msg = '';

            variables = {
              ...variables,
              caseSFID: sfid,
            };
            const recursiveCallApiUpdate = () => {
              createUsageSheetAfterSfid({
                variables,
              })
                .then(response => {
                  if (response?.data !== null) {
                    const { externalId } = response.data.createUsageSheetAfterSfid;
                    if (externalId && !lineItems) {
                      msg = `${usageType} has been created successfully`;
                      setLoading(false);
                      openSnackBar(NOTIFY_TYPE.SUCCESS, msg);
                      setTimeout(() => {
                        history.push('/OrdersUsageDetails', {
                          externalId,
                          formData,
                        });
                        toggleOpen();
                      }, 1000);
                    } else if (externalId && lineItems && lineItems?.length > 0) {
                      const mutation = {
                        // caseId: usageDetails.caseId,
                        caseExternalId: externalId,
                        salesRepId: salesRep && salesRep[0].id,
                        formType: 'New',
                        system: 'Web',
                        version: process.env.REACT_APP_VERSION || '0.0.1',
                        lineItems: formatLineItems(lineItems),
                      };
                      createUpdateUsageLineItems({
                        variables: mutation,
                      })
                        .then(response => {
                          if (
                            response &&
                            response.data &&
                            response.data.createUpdateUsageSheetLineItems &&
                            response.data.createUpdateUsageSheetLineItems.message ===
                              NOTIFY_TYPE.SUCCESS
                          ) {
                            // eslint-disable-next-line no-shadow
                            const { externalId } = response.data.createUpdateUsageSheetLineItems;
                            msg = `${usageType} has been created successfully`;
                            setLoading(false);
                            openSnackBar(NOTIFY_TYPE.SUCCESS, msg);
                            setTimeout(() => {
                              history.push('/OrdersUsageDetails', {
                                externalId,
                                formData,
                              });
                              toggleOpen();
                            }, 1000);
                          } else {
                            setLoading(false);
                            openSnackBar(
                              NOTIFY_TYPE.ERROR,
                              'Something went wrong. Please try again.'
                            );
                          }
                        })
                        .catch(() => {
                          setLoading(false);
                          openSnackBar(
                            NOTIFY_TYPE.ERROR,
                            'Something went wrong. Please try again.'
                          );
                        });
                    }
                  }
                })
                .catch(error => {
                  if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                    recursiveCallApiUpdate();
                  } else {
                    openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                  }
                });
            };
            recursiveCallApiUpdate();
            // to add line items of event order at same time
          } else {
            setLoading(false);
            openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          }
        })
        .catch(() => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        });
    } else {
      createUsageSheet({
        variables,
      })
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.createUsageSheet &&
            res.data.createUsageSheet.message === NOTIFY_TYPE.SUCCESS
          ) {
            const { sfid } = res.data.createUsageSheet;
            let message = '';

            if (!editDetails && !eventUsage) {
              variables = {
                ...variables,
                caseSFID: sfid,
              };
              const recursiveCallApiUpdate = () => {
                createUsageSheetAfterSfid({
                  variables,
                })
                  .then(response => {
                    if (response?.data !== null) {
                      const { externalId } = response.data.createUsageSheetAfterSfid;
                      const msg = `${usageType} has been created successfully`;
                      setLoading(false);
                      openSnackBar(NOTIFY_TYPE.SUCCESS, msg);
                      setTimeout(() => {
                        history.push('/OrdersUsageDetails', {
                          externalId,
                          formData,
                        });
                        toggleOpen();
                      }, 1000);
                    }
                  })
                  .catch(error => {
                    if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                      recursiveCallApiUpdate();
                    } else {
                      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                    }
                  });
              };
              recursiveCallApiUpdate();
            }
            if (editDetails && !eventUsage) {
              message = `${usageType} details updated successfully`;
              const { externalId } = res.data.createUsageSheet;
              setLoading(false);
              openSnackBar(NOTIFY_TYPE.SUCCESS, message);
              setTimeout(() => {
                history.replace('/OrdersUsageDetails', {
                  externalId,
                  formData,
                  type,
                });
                toggleOpen();
                // if (editDetails?.caseExternalId) {
                //   if (refetchDetails) {
                //     refetchDetails();
                //     if (eventUsage) {
                //       setSelectedTab('Details');
                //     }
                //   }
                // } else {
                //   history.push('/OrdersUsageDetails', { externalId, formData });
                // }
              }, 1000);
            }
          } else {
            setLoading(false);
            openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          }
        })
        .catch(() => {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        });
    }
  };

  const saveERPOrder = (result: any): void => {
    const {
      account,
      salesRep,
      shipTo,
      billTo,
      shipMethod,
      procedure,
      contact,
      po,
      surgeryDate,
      patientID,
      internalComments,
      customerComments,
    } = result;
    let variables;
    variables = {
      id: editDetails?.id,
      caseId: editDetails.caseId,
      caseExternalId: editDetails.caseExternalId,
      submitType: 'Update',
      requestedFrom: orderType === USAGE_TYPE.SHIP_AND_BILL ? 'ShipAndBill' : 'BillOnly',
      salesRepId: salesRep && salesRep[0].id,
      accountId: account && account[0]?.id,
      shipToId: shipTo && shipTo[0]?.id,
      billToId: billTo && billTo[0]?.id,
    };
    if (shipMethod) {
      variables = {
        ...variables,
        shipMethod: shipMethod[0]?.value?.split('-')?.[0],
      };
    }
    if (procedure) {
      variables = {
        ...variables,
        procedureTypeIds: procedure[0]?.id,
      };
    }
    if (contact) {
      variables = {
        ...variables,
        surgeonId: contact[0]?.id || '',
      };
    }
    if (surgeryDate) {
      variables = {
        ...variables,
        // eventDateTime: getUTCDateTime(surgeryDate),
        eventDateTime: getUTCDateTime(surgeryDate),
      };
    }
    variables = {
      ...variables,
      poNumber: po || '',
    };
    variables = {
      ...variables,
      patientId: patientID || '',
    };
    let comments: Comments[] = [];
    if (internalComments) {
      comments = [
        ...comments,
        {
          comment: internalComments,
          postTime: getUTCDateTime(new Date()),
          commentType: 'Internal',
        },
      ];
    }
    if (customerComments) {
      comments = [
        ...comments,
        {
          comment: customerComments,
          postTime: getUTCDateTime(new Date()),
          commentType: 'Customer',
        },
      ];
    }
    if (comments && comments.length > 0) {
      variables = {
        ...variables,
        comments,
      };
    }
    setLoading(true);
    editErpOrderDetails({
      variables,
    })
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.resubmitOrderDetails &&
          res.data.resubmitOrderDetails.message === NOTIFY_TYPE.SUCCESS
        ) {
          const msg = `${usageType} has been updated successfully`;
          openSnackBar(NOTIFY_TYPE.SUCCESS, msg);
          setTimeout(() => {
            toggleOpen();
            if (refetchDetails) {
              refetchDetails();
            }
          }, 1000);
        } else {
          setLoading(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      })
      .catch(() => {
        setLoading(false);
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      });
  };

  const handleSave = (result: any): void => {
    if (isERPOrder) {
      saveERPOrder(result);
    } else {
      saveOrderRequest(result);
    }
  };

  const handleAccountSelection = useCallback(
    (accountSelectedValue, name) => {
      setAccountSelected(accountSelectedValue);
    },
    [setAccountSelected]
  );

  const getFormFieldValues = (): any => {
    if (editDetails) {
      let procedureTypeList;

      let customerComments;
      let internalComments;
      let cId;
      let iId;
      if (editDetails.proceduresList) {
        procedureTypeList = editDetails.proceduresList.filter(
          (procedure: any) => procedure.type === 'PT'
        );
      } else if (editDetails.procedureId) {
        procedureTypeList = [
          {
            id: editDetails.procedureId,
            label: editDetails.procedureName,
          },
        ];
      }

      if (editDetails?.comments) {
        // eslint-disable-next-line func-names
        editDetails?.comments?.sort(function(a: any, b: any) {
          return a?.postTime?.localeCompare(b.postTime);
        });

        editDetails.comments.map((comment: any) => {
          if (comment.type === 'Customer') {
            customerComments = comment.value;
            cId = comment.id;
          }

          if (comment.type === 'Internal') {
            internalComments = comment.value;
            iId = comment.id;
          }
        });
      }
      // console.log(internalComments, 'internalComments', iId);
      return {
        procedure: procedureTypeList || [],
        customerComments,
        internalComments,
        iId,
        cId,
        // changes for not prepopulating account having E# as they reprsent repstock in R5
        ...(editDetails?.accountNumber &&
          editDetails?.accountNumber?.startsWith('E' || 'e') &&
          editDetails?.accountNumber.startsWith('IT') &&
          editDetails?.accountNumber.startsWith('IH') &&
          orderType === USAGE_TYPE.BILL_ONLY &&
          userInfo?.businessunit !== SPORTS_MED && {
            account: [],
          }),
        ...(!(
          editDetails?.accountNumber &&
          editDetails?.accountNumber?.startsWith('E' || 'e') &&
          editDetails?.accountNumber.startsWith('IT') &&
          editDetails?.accountNumber.startsWith('IH') &&
          orderType === USAGE_TYPE.BILL_ONLY &&
          userInfo?.businessunit !== SPORTS_MED
        ) && {
          account: [
            {
              id: editDetails.accountId,
              label: `${editDetails.accountNumber} ${editDetails.accountName}`,
              branchId: editDetails.branchId,
            },
          ],
        }),
        ...(editDetails.surgeonId && {
          contact: [
            {
              id: editDetails.surgeonId,
              label: `${editDetails.surgeonFirstName} ${editDetails.surgeonLastName}`,
            },
          ],
        }),
        salesRep: [
          {
            id: editDetails.salesRepId,
            label: editDetails.salesRep,
          },
        ],
        surgeryDate: editDetails.eventStartDate || editDetails.eventDateTime,
        ...(editDetails.shippingId && {
          shipTo: [
            {
              id: editDetails.shippingId,
              label: `${editDetails.shippingAddressNumber} - ${editDetails.shippingAddessLine1}, ${editDetails.shippingCity}, ${editDetails.shippingState}, ${editDetails.shippingPostalcode}`,
            },
          ],
        }),
        ...(editDetails.billingId && {
          billTo: [
            {
              id: editDetails.billingId,
              label: `${editDetails.billingAddressNumber} - ${editDetails.billingAddessLine1}, ${editDetails.billingCity}, ${editDetails.billingState}, ${editDetails.billingPostalcode}`,
            },
          ],
        }),
        ...(editDetails.shipMethod && {
          shipMethod: [{ label: editDetails.shipMethod, value: editDetails.shipMethod }],
        }),
        ...(editDetails?.comments &&
          editDetails?.comments?.length > 0 &&
          InternalComment?.length > 0 && {
            internalComments: InternalComment[0] && InternalComment[0].value,
            id: InternalComment[0] && InternalComment[0].id,
          }),
        ...(editDetails?.comments &&
          editDetails?.comments?.length > 0 &&
          CustomerComment?.length > 0 && {
            customerComments: CustomerComment[0] && CustomerComment[0].value,
            id: CustomerComment[0] && CustomerComment[0].id,
          }),
        po: editDetails.poNumber,
        patientID: editDetails.patientId,
      };
    }
    return {};
  };
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={createEventAfterSfidLoading ? '' : handleCancelButton}
        ariaHideApp
        heading={
          <div className="slds-float_left slds-text-title_bold">
            {`${editDetails && !eventUsage ? 'Edit' : 'New'} ${usageType}`}
          </div>
        }
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          {formData && (
            <FormEngine
              formItems={formJson()}
              division="EU"
              apiData={apiDataObj}
              useLazyQueryObject={lazyQueryObject}
              handleSave={handleSave}
              toggleOpen={toggleOpen}
              formValues={getFormFieldValues()}
              isOpen={isOpen}
              selectedTab={selectedTab}
              formType={orderType}
              handleCancelButton={handleCancelButton}
              createEventLoading={createUsageLoading}
              createRequest={createEventAfterSfidLoading}
              handleRadioButtonChange={handleRadioButtonChange}
              selectSalesRep={handleSalesRepSelection}
              accountChecked={accountChecked}
              handleTabChange={handleTabChange}
              partsData={subInventoryWithLotDetails}
              eventUsage={eventUsage}
              isEdit={editDetails}
              handleAccountSelection={handleAccountSelection}
              isUserOpsCsr={isUserOpsCsr}
              type={
                // eslint-disable-next-line no-cond-assign, no-param-reassign
                !!(editDetails && editDetails.status === 'Failed')
              }
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

export default withRouter(UsageSheetForm);
