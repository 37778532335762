/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { FC, ReactNode, useState, useEffect } from 'react';
import moment from 'moment';
// import { format } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import {
  NOTIFY_TYPE,
  ORDERS,
  USAGE_TYPE,
  onHoldUsers,
  OPSCSRUSERS,
  holdTypes,
} from '../../../../util/constants';
import { getEventIcon, getSvgIcon } from '../../../../util/utilityFunctions';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import CREATE_USAGE_SHEET from '../../../../graphql/mutations/createUsageSheet';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import CANCEL_CASE_USAGE from '../../../../graphql/mutations/cancelCaseUsage';
import CancelUsage from '../UsageCancel';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import DisplayRecordIndicator from '../../../Shared/DisplayRecordIndicator';
import CREATE_USAGE_SHEET_AFTER_SFID from '../../../../graphql/mutations/createUsageSheetAfterSfid';
import { GET_USER_CC_HOLD } from '../../../../graphql/getUserCCInfo';
import { GET_USER_HOLD } from '../../../../graphql/getUserHold';
import { setHoldBannerStatus } from '../../../../store/ducks/holdBanner';

interface Props extends RouteComponentProps {
  usageDetails: UsageSheetDetails;
  handleUsageSheetEdit: () => void;
  viewOnly: boolean;
  isAssignUser: boolean;
  isERPOrder: boolean;
  isERPOrderFailed: boolean;
  refetch: () => void;
  detailsConfig?: any;
  type: string;
  handleSubmit: () => void;
  tabIndexVal: any;
}

export interface LineItems {
  sfid?: string;
  externalId?: string;
  productId?: string;
  lotNumber?: string;
  quantity?: string;
  productdescription?: string;
  status?: string;
  workflowAction?: string;
  lotValidation?: string;
}

export interface UsageSheetDetails {
  caseId?: string;
  caseExternalId: string;
  assignedUserId: string;
  salesRepId: string;
  salesRep: string;
  eventType?: any;
  comments: any;
  eventId: string;
  division?: string;
  eventStatus?: string;
  branchId?: string;
  branch?: string;
  accountId?: string;
  accountNumber?: string;
  accountName?: string;
  createdDate?: string;
  eventDateTime: string;
  shippingAddessLine1?: string;
  shippingAddessLine2?: string;
  shippingPostalcode?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingId: string;
  billingAddessLine1?: string;
  billingAddessLine2?: string;
  billingPostalcode?: string;
  billingCity?: string;
  billingState?: string;
  billingId: string;
  poNumber?: string;
  patientId?: string;
  surgeonId?: string;
  surgeonFirstName?: string;
  surgeonLastName?: string;
  surgeonErpCode?: string;
  procedures?: string;
  procedureId?: string;
  procedureName?: string;
  territoryNumber?: string;
  lineItems: LineItems[];
  hospitalName?: string;
  repPrintedName: string;
  hospitalPrintedName: string;
  orderType: string;
  diplayOrderType: string;
  shipMethod?: string;
  attachments: CaseUsageAttachment[];
  status?: string;
  caseNumber: string;
  id?: string;
  orderNumber?: string;
  erpOrderNumber?: string;
  failureReason?: string;
  lineItemFailureReason?: string;
  fromSubInventoryCode?: string;
  salesOrganization?: string;
  serviceLevel?: string;
  surgeryDate?: string;
  createrName?: string;
  shippingAddressNumber?: string;
  billingAddressNumber?: string;
  parentEventId?: string;
  parentEventType?: any;
  parentEventStartDate?: string;
  parentCaseId?: string;
  parentCaseExternalId?: string;
  parentEventSubType?: any;
  showBackorderdWarning?: boolean;
  showRedWarningIcon?: boolean;
  isPrimaryApproval?: boolean;
  isCycleCountInitiated?: boolean;
}

interface CaseUsageAttachment {
  attachmentType: string;
  caseExternalId: string;
  caseId: string;
  createdAt: string;
  createdBy: string;
  id: string;
  imageurl: string;
  name: string;
  recordType: string;
  showOnUsageSheet: boolean;
  user: string;
}

const UsageDetailHeader: FC<Props> = ({
  usageDetails,
  viewOnly,
  isAssignUser,
  history,
  handleUsageSheetEdit,
  isERPOrder,
  isERPOrderFailed,
  refetch,
  detailsConfig,
  type,
  handleSubmit,
  tabIndexVal,
}) => {
  const {
    salesRep,
    salesRepId,
    eventId,
    eventStatus = '',
    eventDateTime,
    branchId,
    shippingId,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingPostalcode,
    shippingCity,
    shippingState,
    billingId,
    billingAddessLine1,
    billingAddessLine2,
    billingPostalcode,
    billingCity,
    billingState,
    poNumber,
    patientId,
    surgeonId,
    surgeonFirstName,
    surgeonLastName,
    procedureId,
    procedureName,
    territoryNumber,
    accountName,
    accountNumber,
    accountId,
    diplayOrderType,
    shipMethod,
    status: orderStatus = '',
    caseNumber,
    erpOrderNumber = '',
    failureReason,
    lineItemFailureReason,
    shippingAddressNumber = '',
    billingAddressNumber = '',
    parentCaseId,
    parentCaseExternalId,
    parentEventStartDate,
    parentEventId,
    parentEventType,
    parentEventSubType,
    showRedWarningIcon,
    showBackorderdWarning,
  } = usageDetails;
  const userInfo = useSelector(getUserInfo);
  const [loading, setLoading] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [createUsageSheet] = useMutation(CREATE_USAGE_SHEET);
  const [createUsageSheetAfterSfid] = useMutation(CREATE_USAGE_SHEET_AFTER_SFID);
  const [openUsageCancelModal, setOpenUsageCancelModal] = useState(false);
  const [addLineItems] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  // const [setCancelCaseUsage] = useMutation(CANCEL_CASE_USAGE);
  const [setCancelCaseUsage, { loading: cancelUsageLoading }] = useMutation(CANCEL_CASE_USAGE);
  const iconBackorder = getSvgIcon('backorder');
  const dispatch = useDispatch();
  const [userOnHold, setUserOnHold] = useState(false);
  const { data: accountHoldFlag } = useQuery(GET_USER_CC_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
      accountId: usageDetails?.accountNumber,
      name: usageDetails?.salesRep,
    },
  });
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });
  const isHoldUser = !!onHoldUsers?.find((rec: any) => rec?.value === userInfo?.personas);

  useEffect(() => {
    if (
      accountHoldFlag?.getCycleCountHoldInfo &&
      accountHoldFlag?.getCycleCountHoldInfo?.length > 0
    ) {
      if (accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === true && isHoldUser) {
        dispatch(setHoldBannerStatus(true));
        setUserOnHold(true);
      } else {
        dispatch(setHoldBannerStatus(false));
        setUserOnHold(false);
      }
    }
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        usageDetails?.diplayOrderType === 'Bill Only' &&
        !userInfo?.allowBillOnly
      ) {
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        usageDetails?.diplayOrderType === 'Ship & Bill'
      ) {
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
      }
    }
  }, [accountHoldFlag, dispatch, isHoldUser, holddata, userInfo, usageDetails]);
  const handleClone = (): void => {
    let variables: any = {};
    variables = {
      formType: 'New',
      requestedFrom: diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only',
      salesRepId,
      accountId,
      branchId,
      shipToId: shippingId,
      billToId: billingId,
      procedureTypeIds: procedureId,
      system: 'Web',
      version: process.env.REACT_APP_VERSION || '0.0.1',
      surgeonId,
    };
    setLoading(true);
    createUsageSheet({
      variables,
    }).then(res => {
      if (
        res &&
        res.data &&
        res.data.createUsageSheet &&
        res.data.createUsageSheet.message === NOTIFY_TYPE.SUCCESS
      ) {
        const { caseId, sfid } = res.data.createUsageSheet;

        variables = {
          ...variables,
          caseSFID: sfid,
        };
        const recursiveCallApiUpdate = () => {
          createUsageSheetAfterSfid({
            variables,
          })
            .then(response => {
              if (response?.data !== null) {
                const { externalId } = response.data.createUsageSheetAfterSfid;
                if (usageDetails.lineItems && usageDetails.lineItems.length > 0) {
                  let parts: Array<any> = [];
                  parts = usageDetails.lineItems.map((product: any) => {
                    return {
                      productId: product.productId,
                      productNumber: product.productNumber,
                      quantity: product.quantity,
                      serialNumber: product.serialNumber,
                      isLotNumber: product?.isLotNumber,
                      actualQuantity: product?.actualQuantity || '',
                      actualPackContent: product?.packContent || '',
                    };
                  });
                  const mutation = {
                    caseId,
                    caseExternalId: externalId,
                    system: 'Web',
                    version: process.env.REACT_APP_VERSION || '0.0.1',
                    salesRepId: usageDetails.salesRepId,
                    formType: 'New',
                    lineItems: parts,
                  };
                  addLineItems({
                    variables: mutation,
                  })
                    .then(() => {
                      setLoading(false);
                      const usageType =
                        diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only';
                      openSnackBar(
                        NOTIFY_TYPE.SUCCESS,
                        `${usageType} has been cloned successfully`
                      );
                      setTimeout(() => {
                        history.push('/OrdersUsageDetails', { externalId });
                      }, 500);
                    })
                    .catch(response => {
                      setLoading(false);
                      if (response && response.errors && response.errors.message) {
                        openSnackBar(NOTIFY_TYPE.ERROR, response.errors.message);
                      } else {
                        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                      }
                    });
                } else {
                  setLoading(false);
                  const usageType =
                    diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only';
                  openSnackBar(NOTIFY_TYPE.SUCCESS, `${usageType} has been cloned successfully`);
                  setTimeout(() => {
                    history.push('/OrdersUsageDetails', { externalId });
                  }, 500);
                }
              }
            })
            .catch(error => {
              if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                recursiveCallApiUpdate();
              } else {
                openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
              }
            });
        };
        recursiveCallApiUpdate();
      } else {
        setLoading(false);
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      }
    });
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getConfiguration = (key: string) => {
    if (detailsConfig) {
      return detailsConfig?.some(
        (orderConfig: { mappingKey: string; isShow: boolean }) =>
          orderConfig.mappingKey === key && orderConfig.isShow === true
      );
    }
    return true;
  };
  const handleUsageSheetCancel = (): void => {
    const mutation = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      externalId: usageDetails?.caseExternalId || '',
      salesRepId: usageDetails?.salesRepId || '',
    };
    setCancelCaseUsage({
      variables: mutation,
    })
      .then(response => {
        if (
          response &&
          response?.data &&
          response?.data?.cancelCaseUsages &&
          response?.data?.cancelCaseUsages?.message &&
          response?.data?.cancelCaseUsages?.message === 'success'
        ) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Case Usage cancelled successfully');
          setOpenUsageCancelModal(false);
          setTimeout(() => {
            refetch();
          }, 1000);
        }
      })
      .catch(error => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error:Case Usage cancellation error.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const handleCancelUsageModal = (): void => {
    setOpenUsageCancelModal(!openUsageCancelModal);
  };

  const handleUsageDropdownSelect = (option: any): void => {
    const { value } = option;
    if (value === 'clone' && !option.disabled) {
      handleClone();
    } else if (!viewOnly || !isAssignUser) {
      // Check For Edit/Cancel
      if (value === 'edit' && !option.disabled) {
        handleUsageSheetEdit();
      } else if (value === 'cancel' && !option.disabled) {
        setOpenUsageCancelModal(!openUsageCancelModal);
        // handleUsageSheetCancel();
      } else if (value === 'submit' || value === 'resubmit') {
        handleSubmit();
      }
    }
    if (value === 'discrepancy') {
      const typeOfOrder =
        diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship and Bill' : 'Bill Only';
      // eslint-disable-next-line no-unused-expressions
      userInfo?.businessRules.forEach((br: any) => {
        if (br.rules === 'Inventory Discrepancy Email Recipient') {
          window.open(
            `mailto: ${br.values}?subject=${typeOfOrder}: ${
              isERPOrder ? caseNumber : eventId
            }&body=`
          );
        }
      });
    }
  };

  const filterRule: any =
    // eslint-disable-next-line no-unused-expressions
    userInfo?.businessRules?.filter((br: any) => {
      if (
        br.rules === 'Clone Ship & Bill Order Request' &&
        diplayOrderType === USAGE_TYPE.SHIP_AND_BILL
      ) {
        return br.isRequired;
      }
      if (br.rules === 'Clone Bill Only Request' && diplayOrderType === USAGE_TYPE.BILL_ONLY) {
        return br.isRequired;
      }
    });
  const isClone = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
  const isUserOpsCsr = OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup
        className="slds-align-middle"
        variant="list"
        id="button-group-page-header-actions"
      >
        <Dropdown
          style={{ marginLeft: '10px', borderRadius: '0.25rem' }}
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Actions"
          options={[
            {
              label: 'Edit',
              value: 'edit',
              disabled: (viewOnly && !isUserOpsCsr) || type === 'Processed' || isAssignUser,
            },
            {
              label: 'Submit',
              value: isERPOrderFailed ? 'resubmit' : 'submit',
              disabled: isERPOrderFailed
                ? !isUserOpsCsr || viewOnly
                : viewOnly || (userOnHold && isHoldUser) || tabIndexVal !== 3,
              onClick: handleSubmit,
            },
            {
              label: 'Clone',
              value: 'clone',
              disabled: isERPOrder || type === 'Review' || !isClone || isAssignUser,
            },
            { label: 'Discrepancy', value: 'discrepancy', disabled: isAssignUser },
            { label: 'Cancel', value: 'cancel', disabled: viewOnly || isAssignUser },
          ]}
          onSelect={(option: any): void => handleUsageDropdownSelect(option)}
        />
        <DisplayRecordIndicator recordInfo={usageDetails} />
      </ButtonGroup>
    </PageHeaderControl>
  );

  const iconPath = getEventIcon(ORDERS);
  // const parenticonPath = getEventIconInner(parentEventType);
  const handleChangeRequest = (): void => {
    history.push('/surgicalDetails', {
      externalId: parentCaseExternalId,
      id: parentCaseId,
    });
  };
  return (
    <IconSettings iconPath="/icons">
      {loading && <Spinner />}
      <SnackBar open={open} notification={notification} />
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px 0px 0px 10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={history.goBack}
        />
      </div>
      <PageHeader
        className="slds-usage-deatails slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label={diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only'}
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">
              {isERPOrder ? caseNumber || eventId : eventId || 'TBD'}
            </span>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }}>
                <EventStatusBadge status={isERPOrder ? orderStatus || eventStatus : eventStatus} />
                {showRedWarningIcon && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      title="Rejected"
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ color: '#b83c27' }}
                      className="slds-icon-text-error"
                    />
                  </div>
                )}
                {showBackorderdWarning && (
                  <img
                    src={iconBackorder}
                    alt="backorder"
                    style={{ width: '20px', height: '15px', marginLeft: '5px' }}
                  />
                )}
              </div>
            </span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {getConfiguration('account') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Territory">
                Account
              </div>
              <div title={territoryNumber}>{`${accountNumber} - ${accountName}`}</div>
            </>
          </li>
        )}

        {getConfiguration('contact') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Surgeon">
                Surgeon
              </div>
              <div>{`${surgeonFirstName || ''} ${surgeonLastName || ''}`}</div>
            </>
          </li>
        )}
        {getConfiguration('procedure') && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Procedure">
                Procedure
              </div>
              <div title={procedureName}>{procedureName}</div>
            </>
          </li>
        )}

        {getConfiguration('salesRep') && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Sales Rep">
                Sales Rep
              </div>
              <div className="slds-tooltip-trigger">
                <div title={salesRep}>{salesRep}</div>
                <span />
              </div>
            </>
          </li>
        )}
        {getConfiguration('surgeryDate') && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Surgery Date">
                Surgery Date
              </div>
              <div title={eventDateTime}>
                {eventDateTime ? moment(eventDateTime).format('L') : '--'}
              </div>
            </>
          </li>
        )}
      </ul>
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {getConfiguration('shipTo') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Ship To">
                Ship To
              </div>
              <div>
                {`${shippingAddressNumber} - ${shippingAddessLine1 || ''},${shippingAddessLine2 ||
                  ''},${shippingCity || ''},${shippingState || ''},${shippingPostalcode || ''}`}
              </div>
            </>
          </li>
        )}
        {getConfiguration('billTo') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Bill To">
                Bill To
              </div>
              <div>
                {`${billingAddressNumber} - ${billingAddessLine1 || ''},${billingAddessLine2 ||
                  ''},${billingCity || ''},${billingState || ''},${billingPostalcode || ''}`}
              </div>
            </>
          </li>
        )}
        {diplayOrderType === USAGE_TYPE.SHIP_AND_BILL && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div style={{ fontWeight: 'bold' }} title="PO">
              Ship Method
            </div>
            <div className="slds-tooltip-trigger">
              <div title={poNumber}>{shipMethod || ''}</div>
            </div>
          </li>
        )}
        {getConfiguration('po') && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="PO">
                PO
              </div>
              <div className="slds-tooltip-trigger">
                <div title={poNumber}>{poNumber}</div>
              </div>
            </>
          </li>
        )}
        {getConfiguration('patientID') && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <>
              <div style={{ fontWeight: 'bold' }} title="Patient ID">
                Patient ID
              </div>
              <div className="slds-tooltip-trigger">
                <div title={patientId}>{patientId}</div>
              </div>
            </>
          </li>
        )}
        {diplayOrderType === USAGE_TYPE.BILL_ONLY && isERPOrder && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div style={{ fontWeight: 'bold' }} title="Patient ID">
              ERP Order
            </div>
            <div className="slds-tooltip-trigger">
              <div title={erpOrderNumber}>{erpOrderNumber || '--'}</div>
            </div>
          </li>
        )}
        {!isERPOrder && diplayOrderType === USAGE_TYPE.BILL_ONLY && (
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div style={{ fontWeight: 'bold' }} title="Related Documents">
              Related Documents
            </div>
            {parentEventId ? (
              <div title={parentEventId}>
                {/* <span>
                  {parentEventType.indexOf('Bill') === -1 && (
                      <img src={parenticonPath} alt="company" />
                    )}
                </span> */}
                <span
                  role="button"
                  tabIndex={0}
                  className="slds-button"
                  onClick={handleChangeRequest}
                  onKeyDown={handleChangeRequest}
                  style={{ cursor: 'pointer' }}
                >
                  {parentEventId}
                </span>
                <span style={{ margin: '5px 5px 5px 5px' }}>
                  {parentEventSubType ||
                    (parentEventType.indexOf('Bill') !== -1 ? 'Bill Only' : parentEventType)}

                  {`,`}
                </span>
                <br />
                <span style={{ margin: '5px 5px 5px 5px' }}>
                  {parentEventType.indexOf('Bill') !== -1 && parentEventStartDate
                    ? moment(parentEventStartDate)
                        .utc()
                        .format('MM/DD/YYYY')
                    : parentEventStartDate
                    ? moment(parentEventStartDate)
                        .utc()
                        .format('MM/DD/YYYY')
                    : '--'}
                </span>
              </div>
            ) : (
              '--'
            )}
          </li>
        )}
      </ul>
      {/* <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
       
      </ul> */}
      {/* extra fields for Orders Bill Only*/}
      {/* extra fields for Orders Ship & Bill */}
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {(isERPOrder || isERPOrderFailed || orderStatus === 'Failed') &&
          diplayOrderType === USAGE_TYPE.BILL_ONLY && (
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div style={{ fontWeight: 'bold' }} title="Related Documents">
                Related Documents
              </div>
              {parentEventId ? (
                <div title={parentEventId}>
                  {/* <span>
                    {parentEventType.indexOf('Bill') === -1 && (
                      <img src={parenticonPath} alt="company" />
                    )}
                  </span> */}
                  <span
                    role="button"
                    tabIndex={0}
                    className="slds-button"
                    onClick={handleChangeRequest}
                    onKeyDown={handleChangeRequest}
                    style={{ cursor: 'pointer' }}
                  >
                    {parentEventId}
                  </span>
                  <span style={{ margin: '5px 5px 5px 5px' }}>
                    {parentEventSubType ||
                      (parentEventType.indexOf('Bill') !== -1 ? 'Bill Only' : parentEventType)}

                    {`,`}
                  </span>
                  <span style={{ margin: '5px 5px 5px 5px' }}>
                    {parentEventType.indexOf('Bill') !== -1 && parentEventStartDate
                      ? moment(parentEventStartDate)
                          .utc()
                          .format('MM/DD/YYYY')
                      : parentEventStartDate
                      ? moment(parentEventStartDate)
                          .utc()
                          .format('MM/DD/YYYY')
                      : '--'}
                  </span>
                </div>
              ) : (
                '--'
              )}
            </li>
          )}
        {diplayOrderType === USAGE_TYPE.SHIP_AND_BILL && isERPOrder && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div style={{ fontWeight: 'bold' }} title="Patient ID">
              ERP Order
            </div>
            <div className="slds-tooltip-trigger">
              <div title={erpOrderNumber}>{erpOrderNumber || '--'}</div>
            </div>
          </li>
        )}
        {(isERPOrderFailed || orderStatus === 'Failed') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div style={{ fontWeight: 'bold' }} title="Failure Reason">
              Failure Reason
            </div>
            <div>{failureReason || '--'}</div>
          </li>
        )}
        {diplayOrderType === USAGE_TYPE.BILL_ONLY &&
          (isERPOrderFailed || orderStatus === 'Failed') && (
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div style={{ fontWeight: 'bold' }} title="Part Failure">
                Part Failure
              </div>
              <div>{lineItemFailureReason || '--'}</div>
            </li>
          )}
      </ul>
      {/* {parentEventId && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div style={{ fontWeight: 'bold' }} title="Surgery Date">
              Event Start Date
            </div>
            <div title={parentEventStartDate || eventDateTime}>
              <div style={{ display: 'flex' }}>
                <p>{moment(parentEventStartDate || eventDateTime).format('L')}</p>
                <p style={{ paddingLeft: '7.5px' }}>
                  {moment(parentEventStartDate || eventDateTime).format('LT')}
                </p>
              </div>
            </div>
          </li>
        </ul>
      )} */}
      {openUsageCancelModal && (
        <CancelUsage
          cancelInventoryModalVisible={openUsageCancelModal}
          handleCancelInventoryModal={handleCancelUsageModal}
          eventId={isERPOrder ? caseNumber || eventId : eventId || 'TBD'}
          cancelInventory={handleUsageSheetCancel}
          cancelInventoryLoading={cancelUsageLoading}
          label={diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only'}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(UsageDetailHeader);
