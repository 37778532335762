/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Card from '@salesforce/design-system-react/components/card';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { UsageSheetDetails } from '../../UsageDetailsHeader';
import SurgerySheetHeader from './SurgerySheetHeader';
import LineItems from '../Parts/LineItems';
import Attachments from './Attachments';
import Signature from './Signature';
import Comments from './Comments';
import '../../index.scss';
import SurgerySheetPdf from './SurgerySheetPdf';
import EmailModal from './EmailModal';
import SnackBar from '../../../../Shared/SnackBar';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import {
  API,
  ATTACHMENT_TYPE,
  EVENT_TYPE,
  NOTIFY_TYPE,
  NonSalesRep,
  USAGE_TYPE,
  // OPSCSRUSERS,
} from '../../../../../util/constants';
import { isValidEmail } from '../../../../../util/utilityFunctions';
import SUBMIT_USAGE_SHEET from '../../../../../graphql/mutations/submitUsageSheet';
import CASE_USAGE_ATTACHMENTS from '../../../../../graphql/mutations/caseUsageAttachments';
import useBlobFileService, { BlobFile } from '../../../../../util/customHooks/useBlobFileService';
import { GET_SAS_URI } from '../../../../../graphql/getSasUri';
import DELETE_ATTACHMENT from '../../../../../graphql/mutations/deleteAttachment';
import RESUBMIT_ORDER_DETAILS from '../../../../../graphql/mutations/resubmitOrderDetails';
import { getUserInfo, setUserInfo } from '../../../../../store/ducks/userInfo';
import { getUserId } from '../../../../../store/ducks/userId';
import { GET_USER_INFO } from '../../../../../graphql/getUserInfoData';
import { GET_USER_HOLD } from '../../../../../graphql/getUserHold';
// import { GET_PARTS_WITH_LOT_DETAILS } from '../../../../../graphql/getPartWithLotDetails';
// import { GET_USER_TERRITORIES } from '../../../../../graphql/getUserTerritories';
import { GET_PARTS_WITH_LOT_DETAILS } from '../../../../../graphql/getPartWithLotDetails';
import { GET_USER_TERRITORIES } from '../../../../../graphql/getUserTerritories';
import { GET_ADMIN_FROM_CONFIG } from '../../../../../graphql/getAdminFormConfigurations';
import { GET_FORM_CONFIGURATIONS } from '../../../../../graphql/getFormConfigurations';
// import { GET_PARTS_WITH_LOT_DETAILS } from '../../../../../graphql/getPartWithLotDetails';
// import { GET_USER_TERRITORIES } from '../../../../../graphql/getUserTerritories';
import { GET_EMAIL_COMMUNICATION } from '../../../../../graphql/getEmailCommunication';
import EmailHistory from './EmailHistory';
import { startLoading, stopLoading } from '../../../../../store/ducks/spinner';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../../../graphql/getDetailsViewConfigurations';

interface Props extends RouteComponentProps {
  usageDetails?: UsageSheetDetails;
  viewOnly: boolean;
  isAssignUser: boolean;
  refetch: () => void;
  eventType?: string;
  updatedPrice: any;
  isERPOrderFailed: boolean;
  orderResubmitPermission: boolean;
  usageSheetDetailsConfig?: any;
  type: string;
  pricingModalConfig?: any;
  isPartChecked?: any;
  detailsConfig?: any;
  screenLoading?: boolean;
  triggerSubmit: any;
}
interface File {
  id?: string;
  name: string;
  imageurl: string;
  showOnUsageSheet: boolean;
  attachmentType: string;
}

const UsageSurgerySheet: FC<Props> = ({
  usageDetails,
  refetch,
  eventType,
  viewOnly,
  isAssignUser,
  updatedPrice,
  isERPOrderFailed,
  orderResubmitPermission,
  history,
  usageSheetDetailsConfig,
  isPartChecked,
  type,
  pricingModalConfig,
  detailsConfig,
  screenLoading,
  triggerSubmit,
}) => {
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const { data: formConfigurations, loading: formConfigLoading } = useQuery(
    GET_FORM_CONFIGURATIONS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const businessUnit = userInfo?.businessunit || 'Sports Med';
  const { data: getAdminFormConfigurations, loading: adminFormConfigLoading } = useQuery(
    GET_ADMIN_FROM_CONFIG,
    {
      fetchPolicy: 'no-cache',
      variables: {
        businessunit: businessUnit,
        screen: 'Usage Sheet Template',
      },
    }
  );
  const userId = useSelector(getUserId);
  const [loader, setLoader] = useState(false);
  const { open: showSnack, notification, openSnackBar } = useSnackBar();
  const [showPrice, setShowPrice] = useState(usageSheetDetailsConfig.includes('print_pricing'));
  const [generatePdf, setGeneratePdf] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [uploadedFiles, setUploadFiles] = useState<File[]>([]);
  const [customerPrintedName, setCustomerPrintedName] = useState('');
  const [repPrintedName, setRepPrintedName] = useState('');
  const [repImgDataUrl, setRepImgDataUrl] = useState('');
  const [userOnHold, setUserOnHold] = useState(false);
  const [repSigAttachment, setRepSigAttachment] = useState<File>();
  const [custSigAttachment, setCustSigAttachment] = useState<File>();
  const [custImgDataUrl, setCustImgDataUrl] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const { uploadFileToBlobStorage, getBlobUrlWithSas } = useBlobFileService(sasUriData?.getSasUri);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const [saveUsageAttachments] = useMutation(CASE_USAGE_ATTACHMENTS);
  const [submitUsage] = useMutation(SUBMIT_USAGE_SHEET);
  const [reSubmitOrder] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [territory, setTerritory] = useState('');
  const [allComments, setAllComments] = useState([]);
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: usageDetails?.salesRepId,
    },
  });
  // const [territory, setTerritory] = useState('');
  const [documentName, setDocumentName] = useState<any>(
    usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'Usage Sheet' : 'Quote'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isNotSR = !!NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas);
  // const isUserOpsCsr = OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas)
  //   ? true
  //   : false;
  // const isFailed = usageDetails?.status === 'Failed' ? true : false;
  const [getPartsLotDetails, { data: partsLotDetails }] = useLazyQuery(GET_PARTS_WITH_LOT_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  const [
    getUserTerritoriesItems,
    { data: userTerritoriesItems },
  ] = useLazyQuery(GET_USER_TERRITORIES, { fetchPolicy: 'no-cache' });

  const [emailHistory, setEmailHistory] = useState([]);
  const { data: emailCommunication, refetch: refetchEmailCommunication } = useQuery(
    GET_EMAIL_COMMUNICATION,
    {
      variables: {
        caseID: usageDetails?.caseId,
      },
    }
  );
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);

  useEffect(() => {
    refetchEmailCommunication();
    setEmailHistory(emailCommunication?.getEmailCommunication);
  }, [emailCommunication, refetchEmailCommunication, sendingEmail]);

  useEffect(() => {
    if (usageDetails?.salesRepId) {
      getUserTerritoriesItems({
        variables: {
          userId: usageDetails.salesRepId,
        },
      });
    }

    // getPartsLotDetails({
    //   variables: {
    //     productNumber: usageDetails?.lineItems.map((i: any) => {
    //       return { productNumber: i.productNumber, sourceLocation: i.sourceLocation };
    //     }),
    //     subInventoryName: territory,
    //     accountNumber: usageDetails?.accountNumber,
    //     salesRepId: usageDetails?.salesRepId,
    //   },
    // });
  }, [getUserTerritoriesItems, usageDetails, getPartsLotDetails, territory]);

  useEffect(() => {
    if (userTerritoriesItems?.getUserTerritories) {
      const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;
      if (userTerritoriesItemsList.length > 0) {
        setTerritory(userTerritoriesItemsList[0].value);
      }
    }
  }, [userTerritoriesItems]);

  const blobToBase64 = (blob: Blob): Promise<any> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = (): void => {
        resolve(reader.result);
      };
    });
  };

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array.filter((item: any) => {
      const i = newArray.findIndex(x => x?.lotNumber === item?.lotNumber);
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };

  const getControlledLineItems = () => {
    const items = [...(usageDetails?.lineItems || [])];

    if (partsLotDetails?.getPartWithLotDetails) {
      const lotDetails = partsLotDetails.getPartWithLotDetails;
      const lotOptions: any = [];
      lotDetails.forEach((item: any) => {
        const dDiff = 60;
        // dDiff = getDateDifferenceInDays(item.expirationDate, new Date().toString());

        if (item.expirationDate) {
          const expirationDate = moment(item.expirationDate)
            .utc()
            .format('MM/DD/YYYY');
          const data = {
            ...item,
            label: item.lotNumber,
            value: item.lotNumber,
            expiresInDays: dDiff,
          };

          if (usageDetails?.eventDateTime) {
            const surgeryDate = moment(usageDetails?.eventDateTime)
              .utc()
              .format('MM/DD/YYYY');
            if (new Date(expirationDate) >= new Date(surgeryDate)) {
              lotOptions.push(data);
            }
          } else {
            lotOptions.push(data);
          }
        }
      });

      const lineItems = items.map((i: any) => {
        return {
          ...i,
          lotOptions: removeDuplicates(
            lotOptions.filter((item: any) => item?.productNumber === i?.productNumber)
          ),
          // selectedLot: lotOptions.filter((item: any) => item.lotNumber === i.lotNumber),
        };
      });

      return lineItems;
    }
    return items;
  };

  const { data: userInfoData } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  useEffect(() => {
    try {
      dispatch(setUserInfo(userInfoData ? userInfoData.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, userInfoData]);

  useEffect(() => {
    if (usageDetails) {
      const { repPrintedName: repName, hospitalPrintedName, attachments } = usageDetails;
      if (attachments) {
        const imageFiles =
          attachments &&
          attachments.filter((item: any) => item.attachmentType !== ATTACHMENT_TYPE.SIGN);
        setUploadFiles(imageFiles);
      }
      setRepPrintedName(repName || '');
      setCustomerPrintedName(hospitalPrintedName || '');
    }
  }, [usageDetails]);
  useEffect(() => {
    if (usageDetails && sasUriData) {
      const signatures =
        usageDetails.attachments &&
        usageDetails.attachments.filter(
          (item: any) => item.attachmentType === ATTACHMENT_TYPE.SIGN
        );
      if (signatures) {
        signatures.forEach((sign: any): void => {
          const blobUrl = getBlobUrlWithSas(sign.name);
          fetch(blobUrl).then(res => {
            res.blob().then((blob: Blob) => {
              blobToBase64(blob).then(result => {
                if (sign.name.includes('rep_Sig')) {
                  setRepImgDataUrl(result);
                  setRepSigAttachment(sign);
                } else {
                  setCustImgDataUrl(result);
                  setCustSigAttachment(sign);
                }
              });
            });
          });
        });
      }
    }
  }, [getBlobUrlWithSas, sasUriData, usageDetails]);
  const pricingValidation = (lineItems: any[], checkTotal: boolean): string => {
    let errMsg = '';
    // Unit Price Validation
    const totalPrice = lineItems.reduce((acc: any, curr: any) => {
      return acc + Number(curr.usgTotalAmount);
    }, 0);
    let poThreshold = false;
    // removing validation for $10000 to attach a file.
    const usageType = usageDetails?.diplayOrderType ? usageDetails?.diplayOrderType : '';
    // eslint-disable-next-line no-unused-expressions
    userInfo?.businessRules.forEach((br: any) => {
      if (
        br.rules === 'PO Document Threshold' &&
        br.isRequired === true &&
        br.screenName.toLowerCase().indexOf(usageType.toLowerCase()) !== -1
      ) {
        poThreshold = totalPrice > br.values;
      }
    });

    if (checkTotal && poThreshold) {
      // PO attachment is mandate
      const poAttached = uploadedFiles.find((i: any) => i.attachmentType === ATTACHMENT_TYPE.PO);
      if (!poAttached) {
        errMsg += ' PO attachment is missing,';
      }
    }
    // Price Check
    let priceValidationMsg = '';
    const notValidPrice = lineItems.some((i: any) => {
      const contractPriceData = updatedPrice?.getUpdatedResolvedPrice.find(
        (item: any) => item.productNumber === i.productNumber
      );
      const lotValidation = !(
        usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY && i?.priceValidation === 'false'
      );

      let freightValidation = i.isFrieghtMisc;
      if (i.isFrieghtMisc) {
        if (usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL) {
          freightValidation = false;
        }
        /* else if (usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY) {
          freightValidation = false;
        } */
      }
      if (lotValidation && !freightValidation) {
        if (contractPriceData && contractPriceData.resolvedPrice && i.floorPrice) {
          // has both price
          // if (Number(contractPriceData.resolvedPrice) > Number(i.floorPrice)) {
          if (Number(contractPriceData.resolvedPrice) !== Number(i.usgPrice)) {
            if (Number(i.usgPrice) > Number(contractPriceData.resolvedPrice)) {
              priceValidationMsg += ' One or more Unit price is above Contract price,';
            } else if (
              contractPriceData.resolvedPrice >= Number(i.floorPrice) &&
              Number(i.usgPrice) < Number(i.floorPrice) &&
              pricingModalConfig?.includes('floorPrice') &&
              !i.wasted
            ) {
              priceValidationMsg += ' One or more Unit price is below Floor price,';
            }
          }
        } else if (contractPriceData && contractPriceData?.resolvedPrice) {
          // Only Contract Price
          if (Number(i.usgPrice) > Number(contractPriceData.resolvedPrice)) {
            priceValidationMsg += ' One or more Unit price is above Contract price,';
          }
        } else if (
          contractPriceData &&
          contractPriceData?.resolvedPrice >= Number(i.floorPrice) &&
          Number(i.usgPrice) < Number(i.floorPrice) &&
          pricingModalConfig?.includes('floorPrice') &&
          !i.wasted
        ) {
          // Only Floor Price
          priceValidationMsg += ' One or more Unit price is below Floor price,';
        }
      }
      if (priceValidationMsg) {
        return true;
      }
      return false;
    });
    if (notValidPrice) {
      errMsg += priceValidationMsg;
    }
    // Determine List Price Change
    // const listPriceChanged = lineItems.some((i: any) => {
    //   if (i.priceOverride && i.priceOverrideType === 'PERCENTAGE') {
    //     const priceItem = updatedPrice?.getUpdatedResolvedPrice.find(
    //       (price: any) => price.productNumber === i.productNumber
    //     );
    //     const discount = Number(priceItem.listPrice) * (Number(i.usgPrice) / 100);
    //     if (discount !== Number(i.priceOverride)) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    // if (listPriceChanged) {
    //   errMsg += ' One or more List price is changed,';
    // }
    return errMsg;
  };
  const validateGeneratePDF = (): string => {
    let errMsg = '';
    // Skip price validation for Demo and Trail
    if (
      usageDetails &&
      eventType !== EVENT_TYPE.DEMO &&
      eventType !== EVENT_TYPE.TRIAL &&
      !viewOnly
    ) {
      const { lineItems } = usageDetails;
      if (lineItems) {
        errMsg = pricingValidation(lineItems, false);
      }
      if (usageDetails) {
        if (usageDetails.caseId === null) {
          refetch();
          errMsg = 'Wait till Case ID generate';
        }
      }
    }
    return errMsg.replace(/.$/, '.');
  };
  const handleGeneratePdf = (): void => {
    // open pdf modal if valid price
    const validationMsg = validateGeneratePDF();
    const usageType = usageDetails?.diplayOrderType ? usageDetails?.diplayOrderType : '';
    // eslint-disable-next-line no-unused-expressions
    userInfo?.businessRules.forEach((br: any) => {
      if (br.screenName === 'Usage Sheet Template' && br.rules.indexOf(usageType) !== -1) {
        setDocumentName(br.values);
      }
    });
    if (validationMsg) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        `Please provide or correct the following details for PDF generation: ${validationMsg}`
      );
      return;
    }
    setGeneratePdf(true);
  };
  const handleClosePdfModal = (): void => {
    refetch();
    setGeneratePdf(false);
  };
  const handleToggleEmailModal = (): void => {
    setShowEmailModal(!showEmailModal);
  };
  const handleEmail = (): void => {
    setGeneratePdf(false);
    setShowEmailModal(true);
  };
  const handleSendEmail = (
    emailToAddresses: string[],
    emailBody: string,
    subject: string
  ): void => {
    const validEmails = emailToAddresses.every((email: any) => isValidEmail(email));

    if (!validEmails) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Invalid Email(s) exist(s). Please remove to proceed');
      return;
    }
    setSendingEmail(true);
    const withOrWithoutPriceString = showPrice ? '_SurgSheet_WPrice' : '_SurgSheet_WoPrice_';

    // const subjectLine = `${businessUnit} PO Request For/${
    //   usageDetails?.accountNumber
    //     ? `${usageDetails.accountNumber} - ${usageDetails.accountName}`
    //     : ''
    // }/${
    //   usageDetails?.eventId
    //     ? usageDetails.eventId
    //     : usageDetails?.caseNumber
    //     ? usageDetails.caseNumber
    //     : ''
    // }/${
    //   usageDetails?.surgeonFirstName
    //     ? ` ${usageDetails.surgeonFirstName} ${usageDetails.surgeonLastName}`
    //     : ''
    // }/${
    //   usageDetails?.eventDateTime
    //     ? `${moment(new Date(usageDetails.eventDateTime))
    //         .format('MM/DD/YYYY')
    //         .replace(/\//g, '-')}`
    //     : ''
    // }${usageDetails?.createrName ? `/${usageDetails.createrName}` : ''}`;

    const subjectLine = `Stryker Order Request for ${
      usageDetails?.accountNumber
        ? `${usageDetails.accountNumber} - ${usageDetails.accountName}`
        : ''
    }/${usageDetails?.eventId || usageDetails?.caseNumber || ''}/${
      usageDetails?.eventDateTime
        ? `${moment(new Date(usageDetails.eventDateTime))
            .format('MM/DD/YYYY')
            .replace(/\//g, '-')}`
        : ''
    }`;
    const surgeryDate = usageDetails?.eventDateTime
      ? moment(new Date(usageDetails?.eventDateTime))
          .utc()
          .format('MM/DD/YYYY')
      : '';
    try {
      const body = JSON.stringify({
        surgeryDate,
        caseId: usageDetails?.caseId,
        withOrWithoutPriceString,
        showHidePriceFlag: showPrice,
        toAddresses: emailToAddresses.join(';'),
        subject: subject || subjectLine,
        emailFrom: userInfo?.email,
        htmlBody: emailBody.replace(/\n\r?/g, '<br />') || 'Please find attached pdf',
      });

      fetch(`${API.MASTER_DATA.EMAIL_SERVICE}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      }).then((res: any) => {
        setShowEmailModal(false);
        setSendingEmail(false);
        if (res && res.status === 200) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Email has been sent');
        }
      });
    } catch (error) {
      setSendingEmail(false);
      openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again');
    }
  };
  const handleUploadFiles = async (event: any): Promise<void> => {
    const { files } = event.target;
    const file = files[0];
    setShowProgress(true);
    try {
      const response: BlobFile = await uploadFileToBlobStorage(file);
      if (response) {
        const fileToUpload = {
          name: response?.blobName || file.name,
          showOnUsageSheet: false,
          attachmentType: ATTACHMENT_TYPE.USAGE,
          imageurl: response?.imageurl.split('?')[0] || '',
        };
        setUploadFiles([...uploadedFiles, fileToUpload]);
        setShowProgress(false);
        setValuesUpdated(true);
      }
    } catch (error) {
      let message = 'Something went wrong. Please try again.';
      if (error instanceof Error) message = error.message;
      openSnackBar(NOTIFY_TYPE.ERROR, message);
      setShowProgress(false);
    }
  };
  const deleteFile = (id: string): void => {
    setShowProgress(true);
    deleteAttachment({ variables: { id } }).then(() => {
      setShowProgress(false);
      setValuesUpdated(true);
    });
  };
  const handleSave = (): void => {
    const uploadAttachments = uploadedFiles.map((item: File) => {
      return {
        id: item.id,
        imageurl: item.imageurl,
        name: item.name,
        showOnUsageSheet: item.showOnUsageSheet,
        attachmentType: item.attachmentType,
      };
    });
    let sigAttachments: File[] = [];
    if (repSigAttachment) {
      sigAttachments = [
        ...sigAttachments,
        {
          id: repSigAttachment.id,
          imageurl: repSigAttachment.imageurl,
          name: repSigAttachment.name,
          showOnUsageSheet: repSigAttachment.showOnUsageSheet,
          attachmentType: repSigAttachment.attachmentType,
        },
      ];
    }
    if (custSigAttachment) {
      sigAttachments = [
        ...sigAttachments,
        {
          id: custSigAttachment.id,
          imageurl: custSigAttachment.imageurl,
          name: custSigAttachment.name,
          showOnUsageSheet: custSigAttachment.showOnUsageSheet,
          attachmentType: custSigAttachment.attachmentType,
        },
      ];
    }
    const attachments = [...uploadAttachments, ...sigAttachments];
    const variables = {
      caseId: usageDetails?.caseId,
      caseExternalId: usageDetails?.caseExternalId,
      repPrintedName: repPrintedName || usageDetails?.salesRep || '',
      customerPrintedName,
      attachments,
    };
    setLoader(true);
    saveUsageAttachments({
      variables,
    }).then(() => {
      setLoader(false);
      refetch();
    });
  };

  const handleSigAttachment = async (url: string, signType: string): Promise<void> => {
    const fileType = 'image/png';
    const fileName = `${signType}.png`;
    const res = await fetch(url);
    const blob: Blob = await res.blob();
    const file = new File([blob], fileName, { type: fileType });
    const response: BlobFile = await uploadFileToBlobStorage(file);
    const sigFile = {
      name: response?.blobName || file.name,
      showOnUsageSheet: true,
      attachmentType: ATTACHMENT_TYPE.SIGN,
      imageurl: response?.imageurl.split('?')[0] || '',
    };
    if (signType === 'rep_Sig') {
      setRepSigAttachment({ ...sigFile, id: repSigAttachment?.id });
      setRepImgDataUrl(url);
      setValuesUpdated(true);
    } else {
      setCustSigAttachment({ ...sigFile, id: custSigAttachment?.id });
      setCustImgDataUrl(url);
      setValuesUpdated(true);
    }
  };

  useEffect(() => {
    if (valuesUpdated) {
      handleSave();
      setValuesUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesUpdated]);
  const fieldsConfigs = (fieldName: any) => {
    const usageType = usageDetails?.diplayOrderType ? usageDetails?.diplayOrderType : '';
    const fieldConfig: any = userInfo?.businessRules.find((br: any) => {
      if (
        br.rules === fieldName &&
        br.screenName.toLowerCase().indexOf(usageType.toLowerCase()) !== -1
      ) {
        return br;
      }
      return false;
    });
    return fieldConfig?.isRequired;
  };
  const validateUsageSubmission = (): any => {
    let errMsg = '';
    const isSticky = false;

    const usgParts = usageDetails?.lineItems;
    if (!usgParts || usgParts.length === 0) {
      if (errMsg !== '') {
        errMsg += ',';
      }
      errMsg += ' No part(s) added ';
    }

    // condition for bug fix- 527160
    if (usageDetails?.lineItems && usageDetails?.lineItems?.length > 0) {
      const parts = usageDetails?.lineItems;
      const checkNoPart = parts.every(value => {
        return Number(value?.quantity) === 0 ? true : false;
      });
      if (checkNoPart) {
        if (errMsg !== '') {
          errMsg += ',';
        }
        errMsg += ' No part(s) added ';
      }
    }

    if (
      !(usageDetails && usageDetails.poNumber && usageDetails.poNumber.trim()) &&
      fieldsConfigs('PO Number') !== false
    ) {
      if (errMsg !== '') {
        errMsg += ',';
      }
      errMsg += ' PO # is empty ';
    }

    if (usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY) {
      const parts = usageDetails?.lineItems;
      const checkLotNumber = parts.every(value => {
        return value?.lotValidation === 'false'
          ? true
          : value?.lotNumber && value?.lotNumber !== '';
      });
      if (!checkLotNumber) {
        if (errMsg !== '') {
          errMsg += ',';
        }
        errMsg += '  Lot # is not provided for one or more line item(s).';
      }
    }
    if (usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY) {
      const parts = usageDetails?.lineItems;
      let checkexpiredProduct: any = false;

      const config = detailsConfig?.headers?.filter(
        (item: any) => item.mappingKey === 'surgeryDate'
      );

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < parts.length; i++) {
        const value: any = parts[i];
        if (value.expirationDate) {
          const expirationDate = moment(value.expirationDate).format('MM/DD/YYYY');
          const currentDate = moment().format('MM/DD/YYYY');
          if (new Date(expirationDate) < new Date(currentDate)) {
            if (config?.length > 0 && config[0]?.isShow && usageDetails.eventDateTime) {
              const surgeryDate = moment(usageDetails?.eventDateTime).format('MM/DD/YYYY');
              if (new Date(expirationDate) < new Date(surgeryDate)) {
                checkexpiredProduct = true;
                break;
              }
            } else {
              checkexpiredProduct = true;
              break;
            }
          }
        }
      }

      if (checkexpiredProduct) {
        // isSticky = true;
        errMsg +=
          '  Some parts on this order are expired, preventing submission without further intervention. Please contact your CSR team member.';
      }
    }

    if (usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY) {
      const parts = usageDetails?.lineItems;
      const checkSL = parts.every((value: any) => {
        if (value?.sourceLocation) {
          return true;
        }
        if (!value?.sourceLocation && value?.isFrieghtMisc) {
          return true;
        }
        return false;
      });
      if (!checkSL) {
        errMsg += '  Source Location is not provided for one or more line item(s).';
      }
    }
    // Skip price validation for Demo and Trail
    if (
      usageDetails &&
      eventType !== EVENT_TYPE.DEMO &&
      eventType !== EVENT_TYPE.TRIAL &&
      !viewOnly
    ) {
      const { lineItems } = usageDetails;
      if (lineItems) {
        errMsg += pricingValidation(lineItems, true);
      }
    }
    return { message: errMsg.replace(/.$/, '.'), isSticky };
  };
  const getCommentData = (comm: any) => {
    setAllComments(comm);
  };
  const checkConfigVal = (billType: string) => {
    const billonlyOrderConfig = getDetailsViewConfigurations?.getDetailsViewConfigurations;
    const usageTab = billonlyOrderConfig?.[billType]?.usageSheet_tab;
    const check: any = {};
    const usageSheetTab = usageTab?.map((item: any) => {
      if (item.label === 'Customer Comments' && item.isRequired) {
        const customer = allComments?.filter((comment: any) => comment.commentType === 'Customer');
        if (customer?.length > 0) {
          check.isCustomerRequired = false;
        } else {
          check.isCustomerRequired = true;
        }
      }
      if (item.label === 'Internal Comments' && item.isRequired) {
        const internal = allComments?.filter((comment: any) => comment.commentType === 'Internal');
        if (internal?.length > 0) {
          check.isInternalRequired = false;
        } else {
          check.isInternalRequired = true;
        }
      }
    });
    return check;
  };
  const handleSubmit = (): void => {
    if (usageDetails?.isCycleCountInitiated === true) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
      return;
    }
    const validationDetails = validateUsageSubmission();
    if (validationDetails?.message) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        `Please provide or correct the following details for order submission: ${validationDetails?.message}`
      );
      return;
    }
    let isInventoryOnHold: any = false;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let ishold: any = false;
    ishold = usageDetails?.lineItems.map((item: any): any => {
      if (item?.productOnHold) {
        isInventoryOnHold = true;
      }
    });
    if (isInventoryOnHold === true) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
      return;
    }
    const checkCommentStatus = checkConfigVal(
      usageDetails?.diplayOrderType === 'Bill Only' ? 'billOnly' : 'shipAndBill'
    );
    // if(usageDetails?.eventStatus !== 'Submitted' && checkCommentStatus?.isCustomerRequired && checkCommentStatus?.isInternalRequired){
    if (checkCommentStatus?.isCustomerRequired || checkCommentStatus?.isInternalRequired) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Comment field is required.');
      return;
    }
    const usageType =
      usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let variables;
    variables = {
      caseId: usageDetails?.caseId,
      caseExternalId: usageDetails?.caseExternalId,
      salesRepId: usageDetails?.salesRepId,
      accountId: usageDetails?.accountId,
      branchId: usageDetails?.branchId,
      shipToId: usageDetails?.shippingId,
      billToId: usageDetails?.billingId,
      procedureTypeIds: usageDetails?.procedureId,
      surgeonId: usageDetails?.surgeonId,
      eventDateTime: usageDetails?.eventDateTime
        ? moment(usageDetails?.eventDateTime).format('L')
        : '',
      isUserOnHold: userOnHold,
      poNumber: usageDetails && usageDetails.poNumber ? usageDetails.poNumber.trim() : '',
      patientId: usageDetails?.patientId,
      usageType,
      isPrimaryApproval: usageDetails?.isPrimaryApproval || false,
      submittedByPersona: userInfo?.personas,
      approvalType: type === 'Review' ? 'Review' : 'Submit',
    };

    const controlledLineItems: any = getControlledLineItems();
    let isValidLotSerialCombination = true;

    const isOps = userInfo && userInfo?.personas?.toLowerCase().indexOf('op') > -1;
    const isCSR = userInfo && userInfo?.personas?.toLowerCase().indexOf('csr') > -1;
    const isCSROps =
      userInfo &&
      userInfo?.personas?.toLowerCase().indexOf('csr') > -1 &&
      userInfo?.personas?.toLowerCase().indexOf('op') > -1;

    const lineItems = controlledLineItems?.map((item: any) => {
      if (!item.isValidLotSerialCombination && item?.lotValidation === 'true') {
        isValidLotSerialCombination = false;
      }
      if (isCSROps || isOps) {
        return {
          sfid: item.sfid,
          externalId: item.externalId,
          lotNumber: item.lotNumber,
          isLotNumber: item?.isLotNumber,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          serialNumber: item.serialNumber,
          needByDate: item.needByDate,
          serviceLevel: item.serviceLevel,
          shipToId: item.shipToId,
          iscatalog: item.iscatalog,
          isSelected: !!item.isSelected,
          isControlledItem: item?.lotValidation === 'true',
          actualQuantity: item?.actualQuantity,
          isValidLotSerialCombination: item?.isValidLotSerialCombination,
          overridePrice: item?.overridePrice,
          priceOverride: item?.priceOverride,
          priceOverrideType: item?.priceOverrideType,
          usgTotalAmount: item.usgTotalAmount,
          usgPrice: item.usgPrice,
          wasted: item.wasted,
        };
      }
      return {
        sfid: item.sfid,
        externalId: item.externalId,
        lotNumber: item.lotNumber,
        isLotNumber: item?.isLotNumber,
        productId: item.productId,
        productNumber: item.productNumber,
        quantity: item.quantity,
        serialNumber: item.serialNumber,
        needByDate: item.needByDate,
        serviceLevel: item.serviceLevel,
        shipToId: item.shipToId,
        iscatalog: item.iscatalog,
        isSelected: !!item.isSelected,
        isControlledItem: item?.lotValidation === 'true',
        actualQuantity: item?.actualQuantity,
        isValidLotSerialCombination: item?.isValidLotSerialCombination,
        overridePrice: item?.overridePrice,
        priceOverride: item?.priceOverride,
        priceOverrideType: item?.priceOverrideType,
        usgTotalAmount: item.usgTotalAmount,
        usgPrice: item.usgPrice,
        wasted: item.wasted,
      };
    });

    if (
      usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
      (isCSROps || isOps || isCSR) &&
      type === 'Review' &&
      !isValidLotSerialCombination
    ) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'One or more parts are not available. Inventory needs to be adjusted before submitting.'
      );
      return;
    }

    variables = {
      ...variables,
      lineItems,
      system: 'Web',
      version: process.env.REACT_APP_VERSION || '0.0.1',
    };
    setLoader(true);
    submitUsage({
      variables,
    })
      .then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.submitUsageSheet?.message === NOTIFY_TYPE.SUCCESS) {
          const usageTypeNotify =
            usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL
              ? 'Ship & Bill'
              : 'Bill Only';
          openSnackBar(NOTIFY_TYPE.SUCCESS, `${usageTypeNotify} has been submitted successfully.`);
          setTimeout(() => {
            // refetch();
            if ((isCSROps || isOps || isCSR) && type === 'Review') {
              history.push('/Orders/ReviewOrders');
            } else {
              history.push('/Orders');
            }
          }, 1500);
        }
      })
      .catch(error => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else if (error?.message?.toLowerCase().includes('actual quantity')) {
          openSnackBar(NOTIFY_TYPE.ERROR, `One or more line item doesn't have actual quantity.`);
        } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Order submission error.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
        setLoader(false);
      });
  };
  const handleReSubmitOrder = (): void => {
    if (usageDetails?.isCycleCountInitiated === true) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
      return;
    }
    const validationDetails = validateUsageSubmission();

    if (validationDetails?.message) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        `Please provide or correct the following details for order resubmission: ${validationDetails?.message}`
      );
      return;
    }
    let isInventoryonHold: any = false;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isInventoryOnHold = usageDetails?.lineItems.map((item: any): any => {
      if (item?.productOnHold) {
        isInventoryonHold = true;
      }
      return isInventoryonHold;
    });

    if (isInventoryonHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
      return;
    }
    let variables;
    variables = {
      id: usageDetails?.id,
      salesRepId: usageDetails?.salesRepId,
      accountId: usageDetails?.accountId,
      branchId: usageDetails?.branchId,
      shipToId: usageDetails?.shippingId,
      billToId: usageDetails?.billingId,
      submitType: 'Resubmit',
      requestedFrom:
        usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'BillOnly' : 'ShipAndBill',
      fromSubInventoryCode: usageDetails?.fromSubInventoryCode,
      salesOrganization: usageDetails?.salesOrganization,
      shipMethod: usageDetails?.shipMethod,
      serviceLevel: usageDetails?.serviceLevel,
    };

    const lineItems = usageDetails?.lineItems.map((item: any) => {
      return {
        sfid: item.sfid,
        externalId: item.externalId,
        lotNumber: item.lotNumber,
        productId: item.productId,
        productNumber: item.productNumber,
        quantity: item.quantity,
        isLotNumber: item?.isLotNumber,
        actualQuantity: item?.actualQuantity,
      };
    });
    variables = {
      ...variables,
      lineItems,
    };
    setLoader(true);
    reSubmitOrder({
      variables,
    }).then((res: any) => {
      setLoader(false);
      if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
        const usageType =
          usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL ? 'Ship & Bill' : 'Bill Only';
        openSnackBar(NOTIFY_TYPE.SUCCESS, `${usageType} has been resubmitted successfully.`);
        setTimeout(() => {
          // refetch();
          history.push('/Orders/Failed');
        }, 1500);
      }
    });
  };

  useEffect(() => {
    if (triggerSubmit) {
      // eslint-disable-next-line no-unused-expressions
      isERPOrderFailed ? handleReSubmitOrder() : handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSubmit]);

  useEffect(() => {
    if (loader || formConfigLoading || adminFormConfigLoading) {
      dispatch(startLoading());
    } else {
      dispatch(stopLoading());
    }
  }, [loader, formConfigLoading, adminFormConfigLoading, dispatch]);

  return (
    <IconSettings iconPath="/icons">
      {(loader || formConfigLoading || adminFormConfigLoading || screenLoading) && <Spinner />}
      <SnackBar open={showSnack} notification={notification} />
      <SurgerySheetHeader
        handleGeneratePdf={handleGeneratePdf}
        handleSubmit={handleSubmit}
        handleReSubmitOrder={handleReSubmitOrder}
        handleSave={handleSave}
        viewOnly={viewOnly || isPartChecked || isAssignUser}
        isERPOrderFailed={isERPOrderFailed}
        orderResubmitPermission={orderResubmitPermission}
        usageDetails={usageDetails}
        holddata={holddata}
      />
      <div style={{ padding: '5px 15px' }}>
        <Card
          id="lineItems"
          heading="Print Pricing on Usage Sheet"
          headerActions={
            <IconSettings iconPath="/assets/icons">
              <Checkbox
                labels={{
                  toggleEnabled: '',
                  toggleDisabled: '',
                }}
                // disable={isAssignUser}
                variant="toggle"
                checked={showPrice}
                onChange={(): void => {
                  setShowPrice(!showPrice);
                  handleSave();
                }}
              />
            </IconSettings>
          }
        >
          <hr className="card-divider" />
          <LineItems
            usageDetails={usageDetails}
            showPrice={showPrice}
            eventType={eventType}
            type={type}
          />
        </Card>
        <Attachments
          viewOnly={viewOnly || isAssignUser}
          showProgress={showProgress}
          handleUploadFiles={handleUploadFiles}
          uploadedFiles={uploadedFiles}
          setValuesUpdated={setValuesUpdated}
          deleteFile={deleteFile}
          // eslint-disable-next-line no-shadow
          setUploadFilesData={(data: any): void => setUploadFiles(data)}
        />
        <Comments usageDetails={usageDetails} viewOnly={false} getComments={getCommentData} />
        {(usageSheetDetailsConfig.includes('customer_name_sign') === true ||
          usageSheetDetailsConfig.includes('rep_name_sign') === true) && (
          <Signature
            viewOnly={viewOnly || isAssignUser}
            customerPrintedName={customerPrintedName}
            repPrintedName={repPrintedName}
            repImgDataUrl={repImgDataUrl}
            repNameSign={usageSheetDetailsConfig.includes('rep_name_sign')}
            customerNameSign={usageSheetDetailsConfig.includes('customer_name_sign')}
            custImgDataUrl={custImgDataUrl}
            handleSaveSign={handleSigAttachment}
            onChangeCustName={(value: string): void => setCustomerPrintedName(value)}
            onChangeRepName={(value: string): void => setRepPrintedName(value)}
            handleSave={setValuesUpdated}
          />
        )}
        {/* MP:10042023 added to display Email History */}
        <EmailHistory showProgress={showProgress} emailHistory={emailHistory} />
      </div>
      {generatePdf && (
        <SurgerySheetPdf
          open={generatePdf}
          showPrice={showPrice}
          caseId={usageDetails?.caseId || ''}
          handleClosePdfModal={handleClosePdfModal}
          handleEmail={handleEmail}
          diplayOrderType={usageDetails?.diplayOrderType || ''}
          documentName={documentName}
          surgeryDate={usageDetails?.eventDateTime || ''}
        />
      )}
      {showEmailModal && (
        <EmailModal
          open={showEmailModal}
          usageDetails={usageDetails && usageDetails}
          pdfTitle={
            usageDetails?.eventId
              ? usageDetails?.eventId
              : usageDetails?.caseNumber
              ? usageDetails?.caseNumber
              : 'invoice'
          }
          sendingEmail={sendingEmail}
          handleToggleModal={handleToggleEmailModal}
          handleSendEmail={handleSendEmail}
          adminFormConfigurationsData={getAdminFormConfigurations?.getAdminFormConfigurations}
          formConfigurationsData={formConfigurations?.getFormConfigurations}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(UsageSurgerySheet);
